<template>
  <div :class="customClass" :id="elementId">
    <el-dialog
        ref="qrCodeDialog"
        width="800px"
        custom-class="qrcode-dialog"
        :visible.sync="dialogVisible"
        :lock-scroll="true"
        :destroy-on-close="true"
        :append-to-body="true"
    >
      <!--      <c-pre>{{ raceInformation }}</c-pre>-->
      <div slot="title" fc style="padding-top: 20px;">
        <span fz28 font-weight-500>您可以选择以下方式进行报名</span>
      </div>

      <main fc pb10 pl20 pr20>
        <div class="left-qrcode" fc>
          <img class="left-qrcode__img" :src="shuzixindongQRCode" alt="">
          <div class="left-qrcode__text">
            <p style="text-align: center;margin:0;">推荐使用：数字心动APP</p>
            <p style="text-align: center;margin:5px 0 0 0;">（查询中签结果、比赛成绩方便快捷）</p>
          </div>
        </div>

        <div style="width:124px;"></div>

        <div class="right-qrcode" fc>
          <div class="right-qrcode__img" id="h5-registration-on-mobile" style="outline: 1px solid #ccc"></div>
          <div class="right-qrcode__text">
            <p style="text-align: center;margin:0;">手机端H5报名</p>
            <!-- <p style="text-align: center">（仅支持个人报名）</p>-->
          </div>
        </div>
      </main>

      <div style="height:102px;"></div>

      <!-- <c-pre>{{ formObject.raceFormObject }}</c-pre>-->
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">

import QrcodeVue                                        from 'qrcode.vue'
import {mapMutations, mapActions, mapState, mapGetters} from 'vuex'
import DataConfigMixin                                  from '@/mixins/data-config-mixin'

export default {
  mixins    : [DataConfigMixin],
  components: { QrcodeVue },
  name      : 'SignupOrSearchDialog',
  props     : {
    className: { type: String, default: '' },
    visible  : { type: Boolean, default: false },
  },
  data() {
    return {
      autoUpload: false,
      qrcode    : null,
    }
  },
  watch   : {
    visible: {
      handler  : function (val, oldVal) {
        if (val == true) {
          this.$nextTick(() => {
            let elementById = document.getElementById('h5-registration-on-mobile')
            // console.log('elementById = ', elementById)
            // const borderWidth = 20

            let clientWidth       = 380
            let clientHeight      = 380
            let scale             = Number(clientWidth) / 190
            const curtainWidth    = clientWidth * scale
            const curtainHeight   = clientHeight * scale
            // const borderWidth   = 20 * scale
            const borderWidth     = curtainWidth * 0.15
            // const iconRadius      = 4 * scale
            const iconRadius      = curtainWidth * 0.03
            // const iconBorderWidth = 3 * scale
            const iconBorderWidth = curtainWidth * 0.03

            var qrcode  = new QRCode(elementById, {
              //二维码内容
              text: this.raceInformation.signUpUrl,
              //二维码宽度
              width: curtainWidth - borderWidth,
              //二维码高度
              height: curtainHeight - borderWidth,
              //二维码前景色
              colorDark: '#000000',
              //二维码背景色
              colorLight: '#FFFFFF',
              /*!
              容错级别，可设置为：
              QRCode.CorrectLevel.L
              QRCode.CorrectLevel.M
              QRCode.CorrectLevel.Q
              QRCode.CorrectLevel.H
              */
              // correctLevel: QRCode.CorrectLevel.H,
              // correctLevel: QRCode.CorrectLevel.L,
              // correctLevel: QRCode.CorrectLevel.M,
              correctLevel: QRCode.CorrectLevel.Q,
              // correctLevel: QRCode.CorrectLevel.H,
              //二维码中心图片
              // iconSrc: 'http://www.365mini.com/static/image/cry.gif',
              // iconSrc: '/favicon.ico',
              // iconSrc: this.normalizeImageUrl(this.raceInformation.imgDetail),
              iconSrc: this.imgDetail,
              //二维码中心图片边框弧度
              iconRadius: iconRadius,
              //二维码中心图片边框宽度
              iconBorderWidth: iconBorderWidth,
              //二维码中心图片边框颜色
              iconBorderColor: '#FFF',
              //外围幕布（或画布）的宽度
              curtainWidth: curtainWidth,
              //外围幕布（或画布）的高度
              curtainHeight: curtainHeight,
              //外围幕布（或画布）图片
              //curtainImg: "qrcode_border.png",
              //外围幕布（或画布）底色
              //curtainBgColor: "Wheat",
              //二维码相对外围幕布（或画布）的X偏移
              qrcodeOffsetX: borderWidth * 0.5,
              //二维码相对外围幕布（或画布）的Y偏移
              qrcodeOffsetY: borderWidth * 0.5,
            })
            this.qrcode = qrcode
          })
        }
        else {
          //
        }
      },
      deep     : true,
      immediate: true,
    },

    signUpUrl: {
      handler  : function (val, oldVal) {
        if (this.qrcode) {
          this.$nextTick(() => {
            this.qrcode.clear() // clear the code.
            this.qrcode.makeCode(val) // make another code.
          })
        }
      },
      deep     : true,
      immediate: true,
    },
  },
  computed: {
    imgDetail() {
      return this.$store.getters.imgDetail
    },
    shuzixindongQRCode() {
      return this.dataConfig.shuzixindongQRCode
    },
    ...mapGetters({
      raceInformation: 'raceInformation',
    }),
    dialogVisible: {
      // getter
      get() {
        return this.visible
      },
      // setter
      set(newValue) {
        this.$emit('update:visible', newValue)
      },
    },
    elementId() {return `SignupOrSearchDialog_${this._uid}`},
    customClass() {return ['SignupOrSearchDialog', this.className]},
  },
  methods : {},
}
</script>
<style scoped lang="scss" rel="stylesheet/scss">
@import "index.scss";
</style>

<!--<style lang="scss" rel="stylesheet/scss">-->
<!--@import "index.scss";-->
<!--</style>-->
